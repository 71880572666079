import {
  PageState,
  PageActionType,
  SET_CALL_ENDED,
  SET_CALL_STARTED,
  SET_DEVICE_INFO,
  SET_ERROR,
  SET_INITIALIZED,
  SET_PAGE_CONFIG,
  SET_PARTICIPANT_DATA,
  SET_QUEUE_STATE,
  SET_NETWORK_DIAGNOSTIC_PARAMS,
  SET_ZOOM_MEETING_LINK,
} from "./types";

export const initialState: PageState = {
  callData: undefined,
  error: undefined,
  initialized: false,
  networkDiagnosticParams: null,
  pageConfig: null,
  participantData: null,
  queueState: "qNot",
  zoomMeetingLink: undefined,
};

export const rootReducer = (
  state = initialState,
  action: PageActionType
): PageState => {
  switch (action.type) {
    case SET_CALL_ENDED:
      return { ...state, callData: undefined, zoomMeetingLink: undefined };
    case SET_CALL_STARTED:
      return {
        ...state,
        callData: action.payload,
        networkDiagnosticParams: null,
      };
    case SET_DEVICE_INFO:
      return { ...state, deviceInfo: action.payload };
    case SET_ERROR:
      return { ...state, error: action.payload };
    case SET_INITIALIZED:
      return { ...state, initialized: true };
    case SET_NETWORK_DIAGNOSTIC_PARAMS:
      return { ...state, networkDiagnosticParams: action.payload };
    case SET_PAGE_CONFIG:
      return { ...state, pageConfig: action.payload };
    case SET_PARTICIPANT_DATA:
      return { ...state, participantData: action.payload };
    case SET_QUEUE_STATE:
      return {
        ...state,
        callData: action.payload !== "qCalling" ? undefined : state.callData,
        networkDiagnosticParams:
          action.payload === "qUnassigned" || action.payload == "qCalling"
            ? null
            : state.networkDiagnosticParams,
        queueState: action.payload,
      };
    case SET_ZOOM_MEETING_LINK:
      return { ...state, zoomMeetingLink: action.payload };
    default:
      return state;
  }
};
