import type { FunctionComponent } from "react";
import { useSelector } from "react-redux";
import { isMobile } from "react-device-detect";
import { AppBar, Toolbar, Typography } from "@mui/material";
import { RootState } from "../state";
import { ENVIRONMENT_ICON_URL } from "../utilities/constants";
import { useSetDisplayComponent } from "../utilities/customHooks";

type Props = { isLandscape: boolean };

const Header: FunctionComponent<Props> = (props) => {
  const { isLandscape } = props;

  const { navbarTitle = "" } = useSelector(
    (state: RootState) => state.pageConfig?.content
  );
  const callData = useSelector((state: RootState) => state.callData);

  const displayEnvironmentIcon =
    ENVIRONMENT_ICON_URL !== null &&
    ENVIRONMENT_ICON_URL !== "null" &&
    ENVIRONMENT_ICON_URL.length > 0;

  const displayHeader = useSetDisplayComponent(callData, isLandscape, isMobile);

  if (!displayHeader) return null;
  else {
    return (
      <header>
        <AppBar className="app-bar" position="static">
          <Toolbar>
            <Typography
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
              }}
              variant={"subtitle2"}
            >
              {displayEnvironmentIcon && (
                <img
                  src={ENVIRONMENT_ICON_URL}
                  style={{
                    height: "25px",
                    marginRight: "10px",
                    width: "auto",
                  }}
                />
              )}
              {navbarTitle}
            </Typography>
          </Toolbar>
        </AppBar>
      </header>
    );
  }
};

export default Header;
