import type { FunctionComponent } from "react";

import { CircularProgress, Grid } from "@mui/material";

const Connecting: FunctionComponent = () => {
  return (
    <Grid container justifyContent="center" spacing={1}>
      <CircularProgress role="connecting" />
      <span className="sr-only">Connecting....</span>
    </Grid>
  );
};

export default Connecting;
