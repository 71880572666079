import { useSelector } from "react-redux";
import { determineFaceTimeSupport } from "@wolzienllc/vcc-react-commons";
import AssignedAlert from "./AssignedAlert";
import CallingAlert from "./CallingAlert";
import Connecting from "./Connecting";
import ErrorAlert from "./ErrorAlert";
import FacetimeForm from "./FacetimeForm";
import Tips from "./Tips";

const ContentAreaText = () => {
  const { error, initialized, participantData, queueState } = useSelector(
    (state) => state
  );

  const shouldRequestFaceTime = false;
  const participantOnFaceTimeDevice = determineFaceTimeSupport();

  const shouldDisplayTips = ["qNot", "qReady", "qUnassigned"].includes(
    queueState
  );

  const shouldDisplayFacetimePrompt =
    queueState === "qNot" || queueState === "qReady";

  return (
    <>
      {initialized ? (
        <>
          {error ? (
            <ErrorAlert error={error} />
          ) : (
            <div
              className="fullWidth"
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "1%",
              }}
            >
              {(queueState === "qCalled" || queueState === "qAssigned") && (
                <AssignedAlert />
              )}
              {queueState === "qCalling" && <CallingAlert />}
              {shouldDisplayFacetimePrompt &&
                shouldRequestFaceTime &&
                participantOnFaceTimeDevice &&
                participantData && (
                  <FacetimeForm participantData={participantData} />
                )}
              {shouldDisplayTips && <Tips />}
            </div>
          )}
        </>
      ) : (
        <>{error ? <ErrorAlert error={error} /> : <Connecting />}</>
      )}
    </>
  );
};

export default ContentAreaText;
