import { Fragment, FunctionComponent, useContext, useEffect } from "react";
import { isMobile, withOrientationChange } from "react-device-detect";
import { useDispatch, useSelector } from "react-redux";
import { CircularProgress } from "@mui/material";
import Header from "./Components/Header";
import Home from "./Components/Home";
import { useSentryUserSetter } from "./utilities/customHooks";
import { getConfigs } from "./utilities/helperFuncs";
import { SocketContext } from "./utilities/socketService";
import { getDeviceInfo } from "@wolzienllc/vcc-react-commons";
import {
  setDeviceInfo,
  setError,
  setInitialized,
  setPageConfig,
  setParticipantData,
} from "./state/actions";
import { RootState } from "./state";
import * as Sentry from "@sentry/react";
import "./App.css";
import { PageErrorType } from "./state/types";

import {
  ThemeProvider,
  Theme,
  StyledEngineProvider,
  createTheme,
} from "@mui/material/styles";
import { amber, blueGrey, lightGreen, red } from "@mui/material/colors";

declare module "@mui/styles/defaultTheme" {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

const theme = createTheme({
  typography: {
    fontFamily: "'Open Sans', sans-serif",
    fontSize: 15,
  },
  palette: {
    info: blueGrey,
    success: lightGreen,
    mode: "light",
    secondary: red,
    warning: amber,
  },
});

type Props = {
  isLandscape?: boolean;
};

const App: FunctionComponent<Props> = (props) => {
  const { isLandscape } = props;
  const socketService = useContext(SocketContext);
  const error = useSelector((state: RootState) => state.error);

  const pageConfig = useSelector((state: RootState) => state.pageConfig);

  const participantData = useSelector(
    (state: RootState) => state.participantData
  );

  useSentryUserSetter(participantData);

  const dispatch = useDispatch();

  const incorrectParamsMessage = "Incorrect parameters provided.";

  // handles loading the template
  useEffect(() => {
    if (!pageConfig) {
      (async () => {
        try {
          const params = new URLSearchParams(
            document.location.search.substring(1)
          );

          const sessionGuid = params.get("session");

          if (!sessionGuid) {
            throw new Error(incorrectParamsMessage);
          }

          const { page: pageConfig, participant: participantConfig } =
            await getConfigs(sessionGuid);

          dispatch(setPageConfig(pageConfig));
          dispatch(setParticipantData(participantConfig));

          const deviceInfo = getDeviceInfo();
          dispatch(setDeviceInfo(deviceInfo));

          dispatch(setInitialized());

          socketService.init(sessionGuid, deviceInfo);
        } catch (err) {
          try {
            const res = await err?.response?.json?.();

            if (res?.pageConfig) {
              dispatch(setPageConfig(res.pageConfig));
            }
          } catch (err) {
            console.warn(err);
          }

          const status = err?.response?.status;

          if (status === 403) {
            dispatch(setError({ type: PageErrorType.InvitationInUse }));
          } else if (status === 404) {
            dispatch(setError({ type: PageErrorType.InvitationInvalid }));
          } else {
            dispatch(setError({ message: err?.message }));
          }
        }

        return () => socketService.disconnect();
      })();
    }
  }, [dispatch, pageConfig, socketService]);

  let wakeLock = null;

  const requestWakeLock = async () => {
    try {
      // @ts-ignore
      wakeLock = await navigator?.wakeLock?.request("screen");

      wakeLock?.addEventListener("release", () => {
        console.log("Screen Wake Lock released:", wakeLock?.released);
      });
      //console.log("Screen Wake Lock engaged:", wakeLock.released);
    } catch (err) {
      console.error(`Screen Wake Lock prevention error: ${err.message}`);
    }
  };

  useEffect(() => {
    requestWakeLock();

    return () => {
      if (Boolean(wakeLock)) {
        wakeLock.release();
        wakeLock = null;
      }
    };
  }, []);

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <div data-testid="App" id="app">
          {pageConfig ? (
            <Fragment>
              <Header isLandscape={isLandscape} />
              <Home isLandscape={isLandscape} />
            </Fragment>
          ) : (
            <Fragment>
              <div
                id={
                  isMobile
                    ? "main-content-wrapper-mobile"
                    : "main-content-wrapper"
                }
                style={{
                  alignItems: "center",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "flex-start",
                }}
              >
                {Boolean(error) ? (
                  <div>{`An error occured: ${
                    error.message || "Unknown error."
                  }`}</div>
                ) : (
                  <CircularProgress />
                )}
              </div>
            </Fragment>
          )}
        </div>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

export default Sentry.withProfiler(withOrientationChange(App));
