import type { FunctionComponent } from "react";
import { useEffect, useState, Fragment } from "react";
import { isMobile } from "react-device-detect";
import "./TipsSlideShow.css";

const NORMAL_WIDTH = "40vw";
const MOBILE_PORTRAIT_WIDTH = "90vw";
const MOBILE_LANDSCAPE_WIDTH = "40vw";

type WidthOptions =
  | typeof NORMAL_WIDTH
  | typeof MOBILE_LANDSCAPE_WIDTH
  | typeof MOBILE_PORTRAIT_WIDTH;

type TipImageProps = {
  currentId: number;
  currentImage: string;
};

const TipImage: FunctionComponent<TipImageProps> = (props) => {
  const { currentId, currentImage } = props;

  return (
    <div className="tipImageContainer" id={`tip-container-${currentId}`}>
      <img id={`tip-image-${currentId}`} src={currentImage} />
    </div>
  );
};

type Props = {
  isLandscape: boolean;
};

const TipsSlideShow: FunctionComponent<Props> = (props) => {
  const { isLandscape } = props;

  let tipImages = [];
  for (var i = 1; i < 16; i++) {
    tipImages.push(`/img/tips/tips${i < 10 ? `0${i}` : i}.png`);
  }

  const [currentIndex, setCurrentIndex] = useState<number>(0);
  const [imageWidth, setImageWidth] = useState<WidthOptions>(NORMAL_WIDTH);

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (currentIndex === tipImages.length - 1) {
        setCurrentIndex(0);
      } else {
        setCurrentIndex(currentIndex + 1);
      }
    }, 3000);

    return () => {
      clearInterval(intervalId);
    };
  }, [currentIndex]);

  useEffect(() => {
    if (isMobile && isLandscape) {
      setImageWidth(MOBILE_LANDSCAPE_WIDTH);
    } else if (isMobile && !isLandscape) {
      setImageWidth(MOBILE_PORTRAIT_WIDTH);
    } else {
      setImageWidth(NORMAL_WIDTH);
    }
  }, [isMobile, isLandscape]);

  return (
    <Fragment>
      <div
        style={{
          aspectRatio: "16/9",
          height: "auto",
          maxHeight: "540px",
          maxWidth: "960px",
          width: imageWidth,
        }}
      >
        <TipImage
          currentId={currentIndex + 1}
          currentImage={tipImages[currentIndex]}
        />
      </div>
    </Fragment>
  );
};

export default TipsSlideShow;
