import React from "react";
import { shallowEqual, useSelector } from "react-redux";
import { Alert, AlertTitle, Typography } from "@mui/material";
import { RootState } from "../../state";
import { replaceMessagingText } from "../../utilities/helperFuncs";

const CallingAlert: React.FC = () => {
  const shouldRotateDevice = useSelector((state: RootState) =>
    state.deviceInfo?.device?.type === "desktop" ? false : true
  );
  const callData = useSelector((state: RootState) => state.callData);
  const {
    callingMessage = "",
    callingAccountMessage = "",
    rotateDeviceText = "",
  } = useSelector((state: RootState) => state.pageConfig.content, shallowEqual);

  const headingText = callingMessage; //.replace(/\|callType\|/g, callData.chatApp ?? "");

  return (
    <Alert
      className="fullWidth alert-margin"
      color="error"
      icon={false}
      sx={{
        display: "block",
        textAlign: "center",
      }}
    >
      <AlertTitle sx={{ fontSize: "2rem" }}>{headingText}</AlertTitle>

      {callData?.chatApp === "facetime" && callData?.accountName && (
        <>
          {replaceMessagingText(callingAccountMessage, {
            accountNumber: callData.accountName,
          })}
        </>
      )}
      {(callData?.chatApp === "gruveo" || callData?.chatApp === "twilio") && (
        <>
          {`Please ensure that you have properly set microphone and camera
          permissions for your browser.  ${
            shouldRotateDevice ? rotateDeviceText : ""
          }`}
        </>
      )}
      {callData?.chatApp === "zoom" && (
        <>
          <Typography sx={{ fontSize: "0.85rem" }} variant="subtitle1">
            You may be asked to disable "Virtual Background" or "Blur My
            Background" features to improve call quality. Please turn on your
            camera & microphone once you join the meeting.
          </Typography>
        </>
      )}
    </Alert>
  );
};

export default CallingAlert;
