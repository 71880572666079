import {
  CallData,
  NetworkDiagnosticParams,
  PageActionType,
  PageConfiguration,
  PageError,
  Participant,
  QueueState,
  SET_CALL_ENDED,
  SET_CALL_STARTED,
  SET_DEVICE_INFO,
  SET_ERROR,
  SET_INITIALIZED,
  SET_NETWORK_DIAGNOSTIC_PARAMS,
  SET_PAGE_CONFIG,
  SET_PARTICIPANT_DATA,
  SET_QUEUE_STATE,
  SET_ZOOM_MEETING_LINK,
} from "./types";

export const setCallEnded = (): PageActionType => ({
  type: SET_CALL_ENDED,
});

export const setCallStarted = (data: CallData): PageActionType => ({
  type: SET_CALL_STARTED,
  payload: data,
});

export const setDeviceInfo = (data: any): PageActionType => ({
  type: SET_DEVICE_INFO,
  payload: data,
});

export const setError = (data: PageError): PageActionType => ({
  type: SET_ERROR,
  payload: data,
});

export const setInitialized = (): PageActionType => ({
  type: SET_INITIALIZED,
});

export const setNetworkDiagnosticParams = (
  data: NetworkDiagnosticParams
): PageActionType => ({
  type: SET_NETWORK_DIAGNOSTIC_PARAMS,
  payload: data,
});

export const setPageConfig = (data: PageConfiguration): PageActionType => ({
  type: SET_PAGE_CONFIG,
  payload: data,
});

export const setParticipantData = (data: Participant): PageActionType => ({
  type: SET_PARTICIPANT_DATA,
  payload: data,
});

export const setQueueState = (data: QueueState): PageActionType => ({
  type: SET_QUEUE_STATE,
  payload: data,
});

export const setZoomMeetingLink = (data: string): PageActionType => ({
  type: SET_ZOOM_MEETING_LINK,
  payload: data,
});
