import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { initSentryConfiguration } from "@wolzienllc/vcc-react-commons";

import App from "./App";
import { store } from "./state";
import { SocketContext, SocketService } from "./utilities/socketService";

import "./index.css";

// Initialize Sentry client SDK configuration.
initSentryConfiguration();

const socketService = new SocketService();

ReactDOM.render(
  <Provider store={store}>
    <SocketContext.Provider value={socketService}>
      <App />
    </SocketContext.Provider>
  </Provider>,
  document.getElementById("root")
);
