import { applyMiddleware, compose, createStore } from "redux";
import logger from "redux-logger";
import * as Sentry from "@sentry/react";
import { rootReducer } from "./reducers";

const middleware = process.env.NODE_ENV === "production" ? [] : [logger];

const sentryReduxEnhancer = Sentry.createReduxEnhancer({
  // Optionally pass options
});

export const store = createStore(
  rootReducer,
  compose(applyMiddleware(...middleware), sentryReduxEnhancer)
);

export type RootState = ReturnType<typeof rootReducer>;
