import React from "react";
import { shallowEqual, useSelector } from "react-redux";
import { Alert, AlertTitle } from "@mui/material";
import { RootState } from "../../state";

const AssignedAlert: React.FC = () => {
  const { participantAssignedBody = "", participantAssignedHeading = "" } =
    useSelector((state: RootState) => state.pageConfig.content, shallowEqual);

  return (
    <Alert
      className="fullWidth alert-margin"
      color="error"
      icon={false}
      sx={{
        display: "block",
        textAlign: "center",
      }}
    >
      <AlertTitle sx={{ fontSize: "2rem" }}>
        {participantAssignedHeading}
      </AlertTitle>
      {participantAssignedBody}
    </Alert>
  );
};

export default AssignedAlert;
