import type { FunctionComponent } from "react";
import { Fragment, useContext, useEffect, useState } from "react";
import { Alert, AlertTitle, CircularProgress } from "@mui/material";
import { isMobile } from "react-device-detect";
import { useNetworkDiagnostic } from "@wolzienllc/vcc-react-commons";
import { SocketContext } from "../utilities/socketService";
import { store } from "../state";
import { setNetworkDiagnosticParams } from "../state/actions";

type Props = {
  isLandscape: boolean;
  networkDiagnosticParams: any;
};

const TENACTI_NAME = "Tenaciti™";

const NetworkDiagnostic: FunctionComponent<Props> = (props) => {
  const {
    isLandscape,
    networkDiagnosticParams: { token, options },
  } = props;
  const socketService = useContext(SocketContext);

  const { isRunning, report, error } = useNetworkDiagnostic(token, options);

  const clearParams = () => {
    store.dispatch(setNetworkDiagnosticParams(null));
  };

  const [isMobileLandscape, setIsMobileLandscape] = useState<boolean>(
    isMobile && isLandscape
  );

  useEffect(() => {
    if (error || report) {
      socketService.emit("networkDiagnosticResult", {
        error,
        report,
      });
      clearParams();
    }

    return () => {
      /* Do nothing */
    };
  }, [error, report, socketService, clearParams]);

  useEffect(() => {
    if (!isMobile) {
      setIsMobileLandscape(false);
    } else {
      setIsMobileLandscape(isLandscape);
    }
  }, [isMobile, isLandscape, setIsMobileLandscape]);

  return isRunning ? (
    <Fragment>
      <br />
      <Alert
        data-testid="network-test-notification"
        iconMapping={
          report
            ? undefined
            : {
                info: <CircularProgress size={isMobileLandscape ? 20 : 40} />,
              }
        }
        severity={report ? "success" : "info"}
        style={{ fontSize: "13px" }}
      >
        <AlertTitle style={{ fontSize: isMobileLandscape ? "10px" : "13px" }}>
          {report ? `Saving Your Results...` : `Analyzing Your Connection...`}
        </AlertTitle>
        {!isMobileLandscape && (
          <>
            {report
              ? `${TENACTI_NAME} has completed it's testing and is submitting your results.  No private or identifying information has been collected.`
              : `${TENACTI_NAME} is collecting basic information about your network connection to help
      ensure that your video call will look and sound as good as possible.  No private or identifying information is being collected.`}
          </>
        )}
      </Alert>
    </Fragment>
  ) : null;
};

export default NetworkDiagnostic;
