import type { Socket } from "socket.io-client";
import { io } from "socket.io-client";
import { createContext } from "react";
import { ListenEvents, eventListeners } from "./eventListeners";
import { STAGEDOOR_ROOT } from "./constants";
import {
  DeviceInfo,
  NetworkDiagnosticReport,
} from "@wolzienllc/vcc-react-commons";

interface EmitEvents {
  networkDiagnosticResult: (data: {
    error?: Error;
    report?: NetworkDiagnosticReport;
  }) => void;
  vipFacetimeUpdate: (data: { facetime: string }) => void;
  zoomNotAvailable: (data: {}) => void;
}
type EmitEventNames = keyof EmitEvents;
type EmitEventParams = Parameters<EmitEvents[EmitEventNames]>;

export class SocketService {
  private socket?: Socket<ListenEvents, EmitEvents>;

  public init(sessionGuid: string, deviceInfo: DeviceInfo): void {
    //console.log(`socketService: init`);
    this.socket = io(STAGEDOOR_ROOT, {
      reconnection: true,
      secure: true,
      query: {
        appName: "stageDoor",
        deviceInfo: deviceInfo
          ? JSON.stringify(deviceInfo)
          : JSON.stringify({}),
        sessionGuid,
      },
    });

    this.attachEventListeners();
  }

  private attachEventListeners(): void {
    //console.log(`socketService: attachEventListeners`);
    if (this.socket) {
      Object.entries(eventListeners).forEach(([event, listener]) => {
        /*console.log("Got socket event");
        console.dir(event);*/
        this.socket.on(event, listener);
      });
    }
  }

  private detachEventListeners(): void {
    //console.log(`socketService: detachEventListeners`);
    if (this.socket) {
      Object.entries(eventListeners).forEach(([event, listener]) => {
        this.socket.off(event, listener);
      });
    }
  }

  public emit(event: keyof EmitEvents, ...args: EmitEventParams): void {
    this.socket?.emit(event, ...args);
  }

  public disconnect(): void {
    //console.log(`socketService: disconnect`);
    this.detachEventListeners();
    this.socket?.disconnect();
    this.socket = undefined;
  }
}

export const SocketContext = createContext(new SocketService());
