import type { PageConfiguration, Participant } from "../state/types";

import { STAGEDOOR_PATH } from "./constants";
import { apiClient } from "./apiClient";

type Config = {
  page: PageConfiguration;
  participant: Participant;
};

/**
 * @throws when REST request fails
 */
export const getConfigs = async (sessionGuid: string): Promise<Config> => {
  const config = await apiClient
    .get(`${STAGEDOOR_PATH}/configs`, {
      searchParams: {
        sessionGuid,
      },
    })
    .json<Config>();

  return config;
};

export const replaceMessagingText = (
  message: string,
  mapping: { [key: string]: string }
): string => {
  const formattedMessage = Object.entries(mapping).reduce(
    (acc, [key, value]) => {
      const stringToReplace = `{{${key}}}`;
      if (acc.includes(stringToReplace)) {
        return acc.replace(stringToReplace, value);
      } else {
        return acc;
      }
    },
    message
  );

  return formattedMessage;
};
