import { store } from "../state";
import {
  setCallEnded,
  setCallStarted,
  setQueueState,
  setParticipantData,
  setError,
  setNetworkDiagnosticParams,
  setZoomMeetingLink,
} from "../state/actions";
import {
  CallData,
  QueueState,
  NetworkDiagnosticParams,
  Participant,
  PageErrorType,
} from "../state/types";

export interface ListenEvents {
  [event: string]: (...args: any[]) => void;
}

export const eventListeners: ListenEvents = {
  call: (data: CallData) => store.dispatch(setCallStarted(data)),
  endCall: () => store.dispatch(setCallEnded()),
  invitationCancelled: () =>
    store.dispatch(setError({ type: PageErrorType.InvitationCancelled })),
  startNetworkDiagnostic: (data: NetworkDiagnosticParams) =>
    store.dispatch(setNetworkDiagnosticParams(data)),
  stopNetworkDiagnostic: () => store.dispatch(setNetworkDiagnosticParams(null)),
  stateChanged: (data: { response: QueueState }) =>
    store.dispatch(setQueueState(data.response)),
  updateInfoResponse: (data: { response: Participant }) =>
    store.dispatch(setParticipantData(data.response)),
  zoomMeetingLink: (data: { zoomMeetingLink: string }) => {
    store.dispatch(setZoomMeetingLink(data.zoomMeetingLink));
  },
};
