import type { FunctionComponent } from "react";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { isMobile } from "react-device-detect";
import ContentAreaText from "./ContentAreaText";
import Embed from "./Embed";
import { RootState } from "../state";
import Footer from "./Footer";
import NetworkDiagnostic from "./NetworkDiagnostic";
import { useSetDisplayComponent } from "../utilities/customHooks";

type Props = {
  isLandscape: boolean;
};

const NORMAL_WIDTH = "75vw";
const MOBILE_LANDSCAPE_WIDTH = "95vw";
const MOBILE_PORTRAIT_WIDTH = "90vw";

const NORMAL_HEIGHT = "100%";
const MOBILE_LANDSCAPE_HEIGHT = "90vh";
const MOBILE_PORTRAIT_HEIGHT = "45vh";

const Home: FunctionComponent<Props> = (props) => {
  const { isLandscape } = props;

  const { callData, networkDiagnosticParams, queueState } = useSelector(
    (state: RootState) => state
  );

  const displayAlert = useSetDisplayComponent(callData, isLandscape, isMobile);

  const [embedHeight, setEmbedHeight] = useState<string>(NORMAL_HEIGHT);
  const [embedWidth, setEmbedWidth] = useState<string>(NORMAL_WIDTH);
  const [runDiagnostics, setRunDiagnostics] = useState<boolean>(
    ["qAssigned", "qNot", "qReady"].includes(queueState)
  );

  useEffect(() => {
    if (isMobile && isLandscape) {
      setEmbedWidth(MOBILE_LANDSCAPE_WIDTH);
      setEmbedHeight(MOBILE_LANDSCAPE_HEIGHT);
    } else if (isMobile && !isLandscape) {
      setEmbedWidth(MOBILE_PORTRAIT_WIDTH);
      setEmbedHeight(MOBILE_PORTRAIT_HEIGHT);
    } else {
      setEmbedWidth(NORMAL_WIDTH);
      setEmbedHeight(NORMAL_HEIGHT);
    }
  }, [isMobile, isLandscape]);

  useEffect(() => {
    if (callData) {
      setRunDiagnostics(false);
    } else {
      if (networkDiagnosticParams) {
        setRunDiagnostics(
          /*["qAssigned", "qCalled", "qNot", "qReady"].includes(queueState) // TODO: Change server to set user back to qReady on disconnect event if not in call*/
          queueState !== "qCalling"
        );
      } else {
        setRunDiagnostics(false);
      }
    }
  }, [callData, networkDiagnosticParams, queueState, setRunDiagnostics]);

  /*// Testing only
  useEffect(() => {
    console.log(`Current queueState: ${queueState ?? "unknown"}`);

    return () => {
      /* Do nothing /
    };
  }, [queueState]);*/

  return (
    <main>
      <div
        id="foo"
        style={{
          alignItems: "center",
          display: "flex",
          flexDirection: "column",
          height: "100%",
          justifyContent: isLandscape ? "center" : "flex-start",
          width: "100%",
        }}
      >
        {displayAlert && <ContentAreaText />}
        {runDiagnostics && networkDiagnosticParams && (
          <NetworkDiagnostic
            isLandscape={isLandscape}
            networkDiagnosticParams={networkDiagnosticParams}
          />
        )}
        {queueState === "qCalling" && callData && (
          <div style={{ height: embedHeight, width: embedWidth }}>
            <Embed
              callData={callData}
              data-testid="Embed"
              inDisconnectedCalling={false}
            />
          </div>
        )}
      </div>
      <Footer />
    </main>
  );
};

export default Home;
