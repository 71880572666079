import type { Participant } from "../state/types";

import { useEffect, useState } from "react";
import { setUser } from "@sentry/react";

export const useSentryUserSetter = (participantData?: Participant): void => {
  const info = participantData || ({} as any);

  const { guid, contacts = [], fullName } = info;

  const email = contacts.find((c) => c.type === "email");

  useEffect(() => {
    if (guid) {
      //console.log("Attaching user info to logged events.");

      setUser({
        id: guid,
        email: email || "",
        username: fullName,
      });
    }
  }, [guid, email, fullName]);
};

export const useSetDisplayComponent = (
  callData: any,
  isLandscape: boolean,
  isMobile: boolean
): boolean => {
  const [displayComponent, setDisplayComponent] = useState(true);

  useEffect(() => {
    if (isMobile && isLandscape && callData) {
      const { chatApp } = callData;
      if (chatApp !== "facetime" && chatApp !== "zoom") {
        setDisplayComponent(false);
      }
    } else {
      setDisplayComponent(true);
    }
  }, [callData, isLandscape]);

  return displayComponent;
};
