import type { FunctionComponent } from "react";
import { useContext, useEffect, useMemo, useRef, useState } from "react";
import { Button, Grid, Link, Typography } from "@mui/material";
import Countdown from "react-countdown";
import { SocketContext } from "../../utilities/socketService";

/** In order to trigger launch of locally installed Zoom client, convert a URL like "https://zoom.us/x/MEETING_ID?pwd=PWD" to one like "zoommtg://zoom.us/join?action=join&confno=MEETING__ID&pwd=PWD"*/
const generateZoomLauncherLink = (originalUrl: string) => {
  // If url is a custom zoom domain then we'll need to use something like ```const prefix = origin || `${url.protocol}//${url.host}`;```
  return `${originalUrl
    .replace(
      originalUrl.substring(0, 18),
      `zoomus://zoom.us/join?action=join&confno=`
    )
    .replace("?pwd=", "&pwd=")}`;
};

type Props = {
  meetingLink?: string;
};

const ZoomEmbed: FunctionComponent<Props> = (props) => {
  const { meetingLink = undefined } = props;

  const startDateRef = useRef(Date.now() + 10000); // 10 Seconds
  const countdownRef = useRef(null);

  const socketService = useContext(SocketContext);

  const [zoomCallLaunched, setZoomCallLaunched] = useState<boolean>(false);

  const [zoomNotAvailableEmitted, setZoomNotAvailableEmitted] =
    useState<boolean>(false);

  const [zoomNotAvailableEnabled, setZoomNotAvailableEnabled] =
    useState<boolean>(false);

  const handleJoinButtonClick = () => {
    countdownRef.current?.stop?.();
    document.getElementById("zoom-launcher-link")?.click();
    setZoomCallLaunched(true);
  };

  const handleNoZoomClick = () => {
    socketService.emit("zoomNotAvailable", {});
    countdownRef.current?.stop?.();
    setZoomNotAvailableEnabled(!zoomNotAvailableEnabled);
    setZoomNotAvailableEmitted(true);
  };

  const defaultRenderer = ({ seconds, completed }) => {
    return (
      <>
        {(zoomCallLaunched || completed) && (
          <Typography style={{ textAlign: "center" }}>
            Please follow any prompts that appear onscreen in order to join the
            Zoom meeting. Please refresh this page if you encounter any
            difficulty.
          </Typography>
        )}
        {!zoomCallLaunched && !zoomNotAvailableEmitted && !completed && (
          <Typography style={{ textAlign: "center" }}>
            We will automatically launch your call in{" "}
            <b color="red">{seconds}</b> {`second${seconds === 1 ? "" : "s"}`};
            To join faster, click the button below.
          </Typography>
        )}
        {zoomNotAvailableEmitted && (
          <Typography style={{ textAlign: "center" }}>
            Thank you for letting us know you do not have the Zoom app. Please
            stand by.
          </Typography>
        )}
      </>
    );
  };

  const launcherLink = useMemo(() => {
    if (meetingLink) {
      return generateZoomLauncherLink(meetingLink);
    }
    return "TBD";
  }, [meetingLink]);

  useEffect(() => {
    countdownRef.current.start();

    return () => {
      /* Do nothing */
    };
  }, [countdownRef]);

  return (
    <>
      <br />
      <Grid
        alignItems="center"
        container
        direction="column"
        justifyContent="flex-start"
      >
        <Grid item>
          <Countdown
            autoStart={false}
            date={startDateRef.current}
            ref={countdownRef}
            renderer={defaultRenderer}
            onComplete={() => {
              document.getElementById("zoom-launcher-link")?.click();
              setZoomCallLaunched(true);
            }}
          />
        </Grid>
        <Grid>
          <br />
          <Button
            color="error"
            disabled={zoomCallLaunched || zoomNotAvailableEmitted}
            disableElevation
            variant="outlined"
            onClick={handleNoZoomClick}
          >
            I Don't Have Zoom
          </Button>
          &nbsp;
          <Button
            disabled={zoomCallLaunched || zoomNotAvailableEmitted}
            disableElevation
            variant="contained"
            onClick={handleJoinButtonClick}
          >
            Join Now
          </Button>
        </Grid>
      </Grid>
      <Link
        href={launcherLink === "TBD" ? undefined : launcherLink}
        id={"zoom-launcher-link"}
        rel="noopener noreferrer"
        style={{ display: "none" }}
      />
    </>
  );
};

export default ZoomEmbed;
